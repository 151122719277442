<template>
  <ion-list>
    <ion-list-header>
      <ion-label>{{ $t('popoveractions') }}</ion-label>
    </ion-list-header>

    <ion-item button @click="editEvent">
      <ion-icon slot="start" name="create" color="white" />
      <ion-label text-wrap>
        {{ $t('editevent') }}
      </ion-label>
    </ion-item>
    <ion-item button @click="confirmDuplicateEvent">
      <ion-icon slot="start" name="copy" color="white" />
      <ion-label text-wrap>
        {{ $t('duplicateevent') }}
      </ion-label>
    </ion-item>
    <ion-item button @click="exportRegistrations" :disabled="loading">
      <ion-icon slot="start" name="download" color="white" />
      <ion-label text-wrap>
        {{ $t('exporteventregistrationsaction') }}
      </ion-label>
    </ion-item>
    <ion-item button v-if="exportOrdersEnabled" @click="exportOrders" :disabled="loading">
      <ion-icon slot="start" name="logo-euro" color="white" />
      <ion-label text-wrap>
        {{ $t('exporteventordersaction') }}
      </ion-label>
    </ion-item>
    <ion-item button v-if="exportCheckinsEnabled" @click="exportCheckins" :disabled="loading">
      <ion-icon slot="start" name="barcode" color="white" />
      <ion-label text-wrap>
        {{ $t('exporteventcheckins') }}
      </ion-label>
    </ion-item>
    <ion-item button v-if="canCancelEvent" @click="confirmCancelEvent" :disabled="loading">
      <ion-icon slot="start" name="remove-circle" color="danger" />
      <ion-label text-wrap>
        {{ $t('canceleventaction') }}
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
  import { CANCEL_EVENT, CANCEL_FUTURE_EVENTS_IN_SERIES, DUPLICATE_EVENT, EXPORT_EVENT_ORDERS } from '@/graphql/mutations';
  import { EXPORT_EVENT_REGISTRATIONS, EXPORT_EVENT_CHECKINS } from '@/graphql/mutations'
  import { FEATURE_FLAG_MAPPING } from "@/configs";
  import { confirm, choose, showToast, TOAST_DANGER, showLoading, captureException } from "@/utils";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";

export default {
  props: ['eventId', 'seriesParentId', 'canCancelEvent'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    exportCheckinsEnabled() {
      return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.EXPORT_CHECKINS, this.$store.getters[CURRENT_USER_GETTER].id, {
        facility_id: this.$parent.event.organizer.id
      });
    },
    exportOrdersEnabled() {
      return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.EXPORT_ORDERS, this.$store.getters[CURRENT_USER_GETTER].id, {
        facility_id: this.$parent.event.organizer.id
      });
    },
  },
  methods: {
    showLoading,
    confirm,
    choose,
    showToast,
    async exportCheckins() {
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: EXPORT_EVENT_CHECKINS,
          variables: {
            event_id: this.eventId
          }
        })

        this.$parent.$emit('exportReady', response.data.exportEventCheckins);
      } catch(err) {
        this.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async exportRegistrations() {
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: EXPORT_EVENT_REGISTRATIONS,
          variables: {
            event_id: this.eventId
          }
        })

        this.$parent.$emit('exportReady', response.data.exportEventRegistrations);
      } catch(err) {
        this.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async exportOrders() {
      this.loading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: EXPORT_EVENT_ORDERS,
          variables: {
            event_id: this.eventId
          }
        })

        this.$parent.$emit('exportReady', response.data.exportEventOrders);
      } catch(err) {
        this.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async editEvent() {
      await this.$router.push({ name: this.$routeNames.EDIT_EVENT });
      await this.$ionic.popoverController.dismiss();
    },
    async confirmCancelEvent() {
      await this.$ionic.popoverController.dismiss();

      if (this.seriesParentId) {
        return this.confirmCancelEventSeries();
      }

      const confirmed = await this.confirm(this.$t('areyousure'), this.$t('canceleventconfirmation'));

      if (!confirmed) {
        return;
      }

      await this.cancelSingleEvent();
    },

    async confirmCancelEventSeries() {
      // Display a choice
      const choices = [
        {
          key: 'FUTURE_ONLY',
          label: this.$t('onlyfutureevents'),
        },
        {
          key: 'SINGLE',
          label: this.$t('onlythisevent'),
        },
      ];

      const choice = await this.choose(this.$t('canceleventseries'), this.$t('canceleventserieswarning'), choices, {asAlert: true});

      if (!choice) {
        return;
      }

      const confirmed = await this.confirm(this.$t('areyousure'), this.$t('canceleventconfirmation'));

      if (!confirmed) {
        return;
      }

      if (choice.key === 'FUTURE_ONLY') {
        return await this.cancelFutureEventsInSeries();
      }

      if (choice.key === 'SINGLE') {
        return await this.cancelSingleEvent();
      }
    },

    async cancelSingleEvent() {
      this.loading = true;
      const p = await this.showLoading(this.$t('cancelingeventpleasewait'));
      try {
        const event = await this.$apollo.mutate({
          mutation: CANCEL_EVENT,
          variables: {
            event_id: this.eventId
          }
        })

        this.$parent.$emit('eventChanged', event);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.loading = false;
        p.dismiss();
      }

    },

    async confirmDuplicateEvent() {
      await this.$ionic.popoverController.dismiss();

      const confirmed = await this.confirm(this.$t('areyousure'), this.$t('duplicateeventconfirmation'));

      if (!confirmed) {
        return;
      }

      await this.duplicateEvent();
    },


    async duplicateEvent() {
      this.loading = true;
      const p = await this.showLoading(this.$t('duplicatingeventpleasewait'));
      try {
        const event = await this.$apollo.mutate({
          mutation: DUPLICATE_EVENT,
          variables: {
            event_id: this.eventId
          }
        })

        await this.$router.push({
          name: this.$routeNames.EDIT_EVENT,
          params: {
            id: event.data.duplicateEvent.id
          }
        });
      } catch (err) {
        await this.showError(err);
      } finally {
        this.loading = false;
        p.dismiss();
      }

    },

    async cancelFutureEventsInSeries() {
      this.loading = true;
      const p = await this.showLoading(this.$t('cancelingeventseriespleasewait'));
      try {
        await this.$apollo.mutate({
          mutation: CANCEL_FUTURE_EVENTS_IN_SERIES,
          variables: {
            parent_id: this.seriesParentId,
          }
        })

        this.$parent.$emit('eventChanged');
      } catch (err) {
        await this.showError(err);
      } finally {
        this.loading = false;
        p.dismiss();
      }

    },

    async showError(err) {
      await this.showToast(err.message.replace('GraphQL error:', '').trim(), TOAST_DANGER, 10000, {showCloseButton: true});

      captureException(err)
    }
  }
}
</script>
